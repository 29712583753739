"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAddresses = exports.importAddress = exports.getAddressesById = exports.getAddresses = exports.fileTransferHeaders = exports.deleteAddresses = exports.defaultAddressData = exports.createAddresses = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultAddressData = exports.defaultAddressData = {
  id: 0,
  enabled: true,
  name: '',
  mobileNumber: '',
  countryId: 0,
  country: {
    id: null
  },
  stateId: 0,
  state: {
    id: null
  },
  cityId: 0,
  city: {
    id: null
  },
  pincodeId: undefined,
  pincode: {
    id: null
  },
  line1: '',
  line2: ''
};
const getAddresses = params => (0, _request.default)({
  url: '/addresses',
  method: 'get',
  params
});
exports.getAddresses = getAddresses;
const getAddressesById = id => (0, _request.default)({
  url: `/addresses/${id}`,
  method: 'get'
});
exports.getAddressesById = getAddressesById;
const updateAddresses = (id, data) => (0, _request.default)({
  url: `/addresses/${id}`,
  method: 'patch',
  data
});
exports.updateAddresses = updateAddresses;
const deleteAddresses = id => (0, _request.default)({
  url: `/addresses/${id}`,
  method: 'delete'
});
exports.deleteAddresses = deleteAddresses;
const createAddresses = data => (0, _request.default)({
  url: '/addresses/',
  method: 'post',
  data
});
exports.createAddresses = createAddresses;
const importAddress = data => (0, _request.default)({
  url: '/addresses/singleImport',
  method: 'post',
  data
});
exports.importAddress = importAddress;