"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
var _sellers = require("@/api/sellers");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _pincode = require("@/api/pincode");
var _sellersUsers = require("@/api/sellers-users");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.validateRequire = (rule, value, callback) => {
      if (value === '') {
        if (rule.field === 'imageURL') {
          this.$message({
            message: 'Upload cover image is required',
            type: 'error'
          });
        } else {
          this.$message({
            message: rule.field + ' is required',
            type: 'error'
          });
        }
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    this.formData = Object.assign({}, _sellers.defaultSellerData);
    this.userFormData = Object.assign({}, _sellersUsers.defaultSellersUserData);
    this.loading = false;
    this.stateList = [];
    this.cityList = [];
    this.pincodeList = [];
    this.avatar = [];
    this.panFile = [];
    this.gstFile = [];
    this.statuses = [{
      key: 'Pending',
      value: 'Pending'
    }, {
      key: 'Approved',
      value: 'Approved'
    }, {
      key: 'Rejected',
      value: 'Rejected'
    }];
    this.genderOptions = [{
      label: 'Male',
      key: 'male'
    }, {
      label: 'Female',
      key: 'female'
    }, {
      label: 'Other',
      key: 'other'
    }];
    this.rules = {
      companyName: [{
        required: true,
        message: 'Please enter name',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }],
      companyNumber: [{
        required: true,
        message: 'Please enter SGL number',
        trigger: 'blur'
      }, {
        pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$/,
        message: 'Please enter valid number',
        trigger: 'blur'
      }],
      sglId: [{
        required: true,
        message: 'Please enter SGL ID',
        trigger: 'blur'
      }],
      // panNo: [
      //   { required: true, message: 'Please enter Pan number', trigger: 'blur' },
      //   {
      //     pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
      //     message: 'Please enter valid number',
      //     trigger: 'blur'
      //   }
      // ],
      // panFile: [
      //   { required: true, message: 'Please upload pan file', trigger: 'blur' },
      // ],
      gstNo: [{
        pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        message: 'Please enter valid number',
        trigger: 'blur'
      }],
      line1: [{
        required: true,
        message: 'Please Address Line 1',
        trigger: 'blur'
      }],
      pincodeId: [{
        required: true,
        message: 'Please select pincode',
        trigger: 'blur'
      }],
      stateId: [{
        required: true,
        message: 'Please select state',
        trigger: 'blur'
      }],
      cityId: [{
        required: true,
        message: 'Please select city',
        trigger: 'blur'
      }],
      firstName: [{
        required: true,
        message: 'Please enter name',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }],
      lastName: [
      // { required: true, message: 'Please enter name', trigger: 'blur' },
      {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }],
      mobileNumber: [{
        required: true,
        message: 'Contact Number is required',
        trigger: 'blur'
      }, {
        pattern: /^\d+$/,
        message: 'Please enter numbers only',
        trigger: 'blur'
      }, {
        min: 10,
        max: 10,
        message: 'Length should be 10',
        trigger: 'blur'
      }],
      emailAddress: [
      // {
      //   required: true,
      //   message: 'Email is required',
      //   trigger: 'blur'
      // },
      {
        type: 'email',
        message: 'Please enter correct email address',
        trigger: ['blur', 'change']
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _sellers.defaultSellerData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _sellers.getSellerById)(id);
      this.formData = data;
      const userData = await (0, _sellersUsers.getSellersUserBySellerId)(id);
      if (userData) {
        this.userFormData = userData;
        this.avatar.push({
          name: userData.firstName,
          url: userData.avatar
        });
      }
      if (this.formData.pincodeId) {
        this.pincodeList = [this.formData.pincode];
      }
      // Just for test
      const title = 'Edit Seller';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (error) {
      // this.$message.error(error.message || error)
    }
  }
  async getPincodeList(query) {
    const data = await (0, _pincode.getPincodes)((0, _utils.getQuery)({
      pincode: query,
      enabled: true,
      filter: {
        enabled: 'eq',
        pincode: '$startsL'
      },
      sort: 'pincode,ASC',
      limit: 10
    }));
    this.pincodeList = data;
  }
  pincodeSelected(selectedPincodeId = 0) {
    // clear city, state, country selection
    this.formData.countryId = null;
    this.formData.stateId = null;
    this.formData.cityId = null;
    if (selectedPincodeId) {
      // find pincode
      const selectedPincode = this.pincodeList.find(pincode => pincode.id === selectedPincodeId);
      if (selectedPincode) {
        // set city, state, country
        this.formData.countryId = selectedPincode.countryId;
        this.formData.country = selectedPincode.country;
        this.formData.stateId = selectedPincode.stateId;
        this.formData.state = selectedPincode.state;
        this.formData.cityId = selectedPincode.cityId;
        this.formData.city = selectedPincode.city;
        this.formData.pincodeId = selectedPincodeId;
        this.formData.pincode = selectedPincode;
      }
    }
  }
  imageUploaded(file) {
    this.userFormData.avatar = file.response;
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title} - ${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  certificateUploaded(file) {
    this.formData.gstCertificate = file.response;
  }
  panFileUploaded(file) {
    this.formData.panFile = file.response;
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _sellers.updateSeller)(this.formData.id, this.formData);
        if (this.userFormData.id) {
          await (0, _sellersUsers.updateSellersUser)(this.userFormData.id, this.userFormData);
        } else {
          this.userFormData.sellerId = this.formData.id;
          await (0, _sellersUsers.createSellersUser)(this.userFormData);
        }
      } else {
        console.log(this.formData);
        const response = await (0, _sellers.createSeller)(this.formData);
        this.userFormData.sellerId = response.id;
        await (0, _sellersUsers.createSellersUser)(this.userFormData);
      }
      this.$notify({
        title: 'Success',
        message: 'Seller saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/seller/list');
    } catch (error) {
      this.loading = false;
    }
  }
  getPincodeRelated() {
    const response = [];
    if (this.formData.country) {
      response.push(this.formData.country.name);
    }
    if (this.formData.state) {
      response.push(this.formData.state.name);
    }
    if (this.formData.city) {
      response.push(this.formData.city.name);
    }
    return response.join(' / ');
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'SellerDetail',
  components: {
    FileUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;