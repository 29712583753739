"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm._numberToWords(),
      placement: "top"
    }
  }, [_c("el-button", {
    staticClass: "inr-button",
    attrs: {
      size: "mini"
    }
  }, [_c("svg-icon", {
    class: _vm.size,
    attrs: {
      name: "inr"
    }
  }), _vm._v(" " + _vm._s(_vm.getFormattedNumber()) + " ")], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;