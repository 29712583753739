"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userDateWiseCount = exports.userCount = exports.updateUser = exports.register = exports.newCustomers = exports.manage = exports.logout = exports.login = exports.getUsers = exports.getUserRoles = exports.getUserCards = exports.getUserById = exports.getUserByEmail = exports.getNotificationsCount = exports.deleteUser = exports.defaultUserData = exports.customerOrders = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultUserData = exports.defaultUserData = {
  id: 0,
  enabled: true,
  emailAddress: undefined,
  mobileNumber: '',
  avatar: '',
  firstName: '',
  lastName: '',
  companyName: undefined,
  isKYCVerified: false,
  panFile: '',
  gstCertificate: '',
  isOnWaitlist: false,
  isEmailVerified: false,
  isMobileVerified: false,
  gender: '',
  countryId: 1,
  currency: 'INR',
  language: '',
  lastLogin: '',
  lifetimeOrdersCount: 0,
  lifetimeOrdersValue: 0,
  lastOrderDate: '',
  lastOrderValue: 0,
  ytdOrderValue: 0,
  averageMonthlyOrder: 0,
  status: 'Pending',
  address: []
};
const getUsers = params => (0, _request.default)({
  url: '/users',
  method: 'get',
  params
});
exports.getUsers = getUsers;
const getUserById = id => (0, _request.default)({
  url: `/users/${id}`,
  method: 'get'
});
exports.getUserById = getUserById;
const getUserByEmail = adminemail => (0, _request.default)({
  url: `/users/${adminemail}`,
  method: 'get'
});
exports.getUserByEmail = getUserByEmail;
const updateUser = (id, data) => (0, _request.default)({
  url: `/users/${id}`,
  method: 'patch',
  data
});
exports.updateUser = updateUser;
const deleteUser = adminname => (0, _request.default)({
  url: `/users/${adminname}`,
  method: 'delete'
});
exports.deleteUser = deleteUser;
const login = data => (0, _request.default)({
  url: '/users/login',
  method: 'post',
  data
});
exports.login = login;
const logout = () => (0, _request.default)({
  url: '/users/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/users/register',
  method: 'post',
  data
});
exports.register = register;
const userCount = params => (0, _request.default)({
  url: '/users/user-count',
  method: 'get',
  params
});
exports.userCount = userCount;
const getUserCards = () => (0, _request.default)({
  url: '/users-cards',
  method: 'get'
});
exports.getUserCards = getUserCards;
const getUserRoles = params => (0, _request.default)({
  url: '/users-roles',
  method: 'get',
  params
});
exports.getUserRoles = getUserRoles;
const getNotificationsCount = data => (0, _request.default)({
  url: '/users/eligible-user-count',
  method: 'post',
  data
});
exports.getNotificationsCount = getNotificationsCount;
const userDateWiseCount = params => (0, _request.default)({
  url: '/users/user-count',
  method: 'get',
  params
});
exports.userDateWiseCount = userDateWiseCount;
const manage = data => (0, _request.default)({
  url: '/users/manage',
  method: 'post',
  data
});
exports.manage = manage;
const newCustomers = params => (0, _request.default)({
  url: '/users/new-customers',
  method: 'get',
  params
});
exports.newCustomers = newCustomers;
const customerOrders = data => (0, _request.default)({
  url: '/orders/total-purchase',
  method: 'post',
  data
});
exports.customerOrders = customerOrders;